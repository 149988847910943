
function SlaDue() {
  return [
    { slaDueID: 301, slaDueName: "Today" },
    { slaDueID: 302, slaDueName: "Next 1 day" },
    { slaDueID: 303, slaDueName: "Next 2 days" },
    { slaDueID: 304, slaDueName: "Next 3 days" },
    { slaDueID: 305, slaDueName: "Next 4 days" },
    { slaDueID: 306, slaDueName: "Next 5 days" },
    { slaDueID: 307, slaDueName: "Next 6 days" },
    { slaDueID: 308, slaDueName: "Next 7 days" }
  ];
}
export default SlaDue;
