function ScheduleDateDropDown(){
    return[
      {scheduleID:'100',scheduleName:'Select'},
      {scheduleID:'230',scheduleName:'Daily'},
      {scheduleID:'231',scheduleName:'Weekly'},
      {scheduleID:'232',scheduleName:'Same day each month'},
      {scheduleID:'233',scheduleName:'Same week each month'},
      {scheduleID:'234',scheduleName:'Same day each year'},
      {scheduleID:'235',scheduleName:'Same week each year'},
    ]
  }
  export default ScheduleDateDropDown;